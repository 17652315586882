<template>
  <div>
    <section class="bg-white p-3 rounded shadow-sm">
      <b-button
        class="float-right"
        size="sm"
        variant="primary"
        @click="refreshData"
      >
        <b-icon
          icon="arrow-clockwise"
          class="mr-1"
        /> Refresh
      </b-button>
      <h2>Daftar Waiting List Pendonor - Aftap</h2>

      <b-form-group label="Pilih Lokasi MU : ">
        <registered-m-u-lists
          v-model="selectedMu"
          @input="handleInputMU"
        />
      </b-form-group>

      <b-table
        :items="waitingListTable.items"
        :fields="waitingListTable.fields"
        :busy="waitingListTable.isBusy"
        responsive
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(aksi)="data">
          <b-button
            v-if="userRole !== 'REGISTER'"
            size="sm"
            variant="info"
            class="mr-1"
            :to="'/dashboards/aftap-registrasi/' + data.item.hb_examinations_id"
          >
            <i class="ri-stethoscope-fill mr-1" />
            Detail
          </b-button>
        </template>
        <template #cell(status)="data">
          <b-badge variant="warning">
            Proses
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="waitingListTable.currentPage"
        align="right"
        :total-rows="waitingListTable.total"
        :per-page="waitingListTable.perPage"
        aria-controls="my-table"
        @change="getWaitingList"
      />
    </section>

    <section class="bg-white p-3 rounded shadow-sm mt-5">
      <h2>Daftar List Pendonor - Aftap</h2>
      <div class="row no-gutters float-right w-full">
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
              @click="getAllAftaf"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="table.search"
            type="search"
            placeholder="Search"
            class="border-left-0"
            @input="debouncedSearch"
          />
        </b-input-group>
      </div>

      <b-table
        class="mt-5"
        :items="itemsFormatted"
        :fields="table.fields"
        :busy="table.isBusy"
        responsive
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(aksi)="data">
          <div
            class="d-flex"
            style="gap: 5px"
          >
            <b-button
              v-if="userRole !== 'REGISTER'"
              size="sm"
              variant="info"
              :to="'/dashboards/aftap/' + data.item.id"
            >
              <i class="ri-stethoscope-fill mr-1" /> Detail
            </b-button>
            <b-button
              size="sm"
              variant="success"
              @click="openModal(data.item.id)"
            >
              Nomor Selang</b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              :to="`/print/${data.item.registration_id}`"
            >
              <b-icon
                icon="menu-up"
                class="mx-2"
              />
              Laporan
            </b-button>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.item.status === 'Done'
                ? 'primary'
                : data.item.status === 'Approved'
                  ? 'info'
                  : 'danger'
            "
          >
            {{
              data.item.status === "Done"
                ? "Selesai"
                : data.item.status === "Approved"
                  ? "Diterima"
                  : "Ditolak"
            }}
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        align="right"
        :total-rows="table.total"
        :per-page="table.perPage"
        aria-controls="my-table"
        @change="getAllAftaf"
      />
    </section>

    <b-modal
      v-model="modalShow"
      cancel-variant="text"
      header-class="align-items-center"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Nomor Selang
        </h5>

        <b-button
          variant="text"
          class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
          style="min-height: 24px"
          @click="close()"
        >
          <i
            class="ri-close-line hp-text-color-dark-0 lh-1"
            style="font-size: 24px"
          />
        </b-button>
      </template>

      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Nomor Selang"
          label-for="nomorSelang-input"
        >
          <b-form-input
            id="nomorSelang-input"
            v-model="nomorSelang"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script setup>
import {
  onMounted, ref, watch, computed,
} from 'vue'
import {
  BTable,
  BPagination,
  BInputGroup,
  BButton,
  BBadge,
  BFormInput,
  BInputGroupPrepend,
  BFormGroup,
  BModal,
} from 'bootstrap-vue'
import manageAftapAPI from '../../../../../../api/manageAftaf/manageAftafAPI'
import RegisteredMULists from '../../../../../../components/shared/RegisteredMULists.vue'

const modalShow = ref(false)

const userRole = ref('')
userRole.value = JSON.parse(localStorage.getItem('userInfo')).role

const selectedMu = ref(null)

const waitingListTable = ref({
  items: [],
  fields: [
    { key: 'status', label: 'Status' },
    { key: 'name', label: 'Nama' },
    { key: 'address', label: 'Alamat' },
    { key: 'aksi', label: 'Aksi' },
  ],
  total: 0,
  perPage: 5,
  currentPage: 1,
  search: '',
  isBusy: false,
})

const table = ref({
  items: [],
  fields: [
    { key: 'status', label: 'Status' },
    { key: 'location_name', label: 'Nama MU' },
    { key: 'name', label: 'Nama Pendonor' },
    { key: 'barcode', label: 'Barcode' },
    { key: 'blood_bag_number', label: 'No Selang' },
    { key: 'blood_bag_number', label: 'No Selang' },
    { key: 'aksi', label: 'Aksi' },
  ],
  total: 0,
  perPage: 5,
  currentPage: 1,
  search: '',
  isBusy: false,
})

const itemsFormatted = computed(() => table.value.items.map(item => ({
  ...item,
  location_name: item.blood_van_name || item.hospital_name || 'N/A',
})))

const aftapDetail = ref({})
const nomorSelang = ref('')

async function getAllAftaf() {
  table.value.isBusy = true
  const { data: response } = await manageAftapAPI.getAll({
    page: table.value.currentPage,
    per_page: table.value.perPage,
    search: table.value.search,
  })
  table.value.items = response.data.data
  table.value.total = response.data.total
  table.value.isBusy = false
}

function debouncedSearch() {
  clearTimeout(table.value.debounceTimer)
  table.value.debounceTimer = setTimeout(() => {
    getAllAftaf()
  }, 500)
}

async function getWaitingList() {
  waitingListTable.value.isBusy = true

  const params = {
    page: waitingListTable.value.currentPage,
    per_page: waitingListTable.value.perPage,
  }

  if (selectedMu.value?.type === 'hospital' && selectedMu.value?.hospital_id) {
    params.hospital_id = selectedMu.value.hospital_id
  } else if (selectedMu.value?.type === 'blood_van' && selectedMu.value?.id) {
    params.blood_van_id = selectedMu.value.id
  }

  const { data: response } = await manageAftapAPI.getWaiting(params)
  waitingListTable.value.items = response.data.data
  waitingListTable.value.total = response.data.total
  waitingListTable.value.isBusy = false
}

function handleInputMU(selectedValue) {
  console.log('Selected value:', selectedValue)
  selectedMu.value = selectedValue

  getWaitingList()
}

async function getAftapDetail(id) {
  const { data: response } = await manageAftapAPI.getDetail(id)
  aftapDetail.value = response.data
}

function openModal(id) {
  modalShow.value = !modalShow.value
  getAftapDetail(id)
}
async function handleOk() {
  const {
    id,
    hb_examinations_id,
    barcode,
    venipuncture_start_time,
    venipuncture_end_time,
    antiseptic_wipe,
    side_effect,
    uptake_reaction,
    status,
  } = aftapDetail.value
  const payload = {
    id,
    hb_examinations_id,
    barcode,
    blood_bag_number: nomorSelang.value,
    venipuncture_start_time,
    venipuncture_end_time,
    antiseptic_wipe,
    side_effect,
    uptake_reaction,
    status,
  };
  nomorSelang.value = "";
  await manageAftapAPI.edit(payload);
  getAllAftaf();
}

function refreshData() {
  getAllAftaf()
  getWaitingList()
}

watch(
  () => table.value.currentPage,
  () => {
    getAllAftaf()
  },
)

watch(
  () => waitingListTable.value.currentPage,
  () => {
    getWaitingList()
  },
)

onMounted(() => {
  getAllAftaf()
  getWaitingList()
})
</script>
